import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput
} from 'react-admin';
import {
  leastOneOption,
  hasValue,
  number
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from '@mui/material';
import EditTitle from '../../ui/edit-title';
import CustomRichTextInput from '../../ui/custom-rich-text-input';
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  exam_group_id: leastOneOption(),
  type_reference_type: leastOneOption(),
  gender_type: leastOneOption(),
  name: hasValue(),
  initial_reference_value: number(),
  final_reference_value: number(),
  ideal_value: number(),
  min_value: number(),
  max_value: number(),
  min_red_left_value: number(),
  max_red_left_value: number(),
  min_orange_left_value: number(),
  max_orange_left_value: number(),
  min_yellow_left_value: number(),
  max_yellow_left_value: number(),
  min_green_value: number(),
  max_green_value: number(),
  min_red_right_value: number(),
  max_red_right_value: number(),
  min_orange_right_value: number(),
  max_orange_right_value: number(),
  min_yellow_right_value: number(),
  max_yellow_right_value: number(),
  description: hasValue()
});

const TypeExamEdit = () =>
{
  return (
    <Edit
      title={ <EditTitle subtitle="Editar Tipo de Exame: " source="name" /> }
      redirect="list"
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={1.5}>
            <TextInput source="id" label="Id" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={3.5}>
              <ReferenceInput source="exam_group_id" sort={{ field: 'name', order: 'ASC' }} reference="setup/exam-groups" >
                <SelectInput label="Grupo" optionText="short_name" optionValue="id" fullWidth />
              </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <ReferenceInput source="type_reference_type" sort={{ field: 'name', order: 'ASC' }} reference="setup/types-reference" >
              <SelectInput label="Referência" optionText="name" optionValue="type" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={3.5}>
            <ReferenceInput source="gender_type" sort={{ field: 'name', order: 'ASC' }} reference="setup/genders" >
              <SelectInput label="Gênero" optionText="name" optionValue="type" fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput source="name" label="Nome" fullWidth />
          </Grid>

          <Grid item xs={12} sm={2.4}>
            <NumberInput source="initial_reference_value" label="Valor Inicial" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <NumberInput source="final_reference_value" label="Valor Final" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <NumberInput source="ideal_value" label="Valor Ideal" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <NumberInput source="min_value" label="Valor Mínimo" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <NumberInput source="max_value" label="Valor Máximo" fullWidth />
          </Grid>

          <Grid item xs={12} sm={2}>
            <NumberInput source="min_red_left_value" label="Mínimo Vermelho Esquerda" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="max_red_left_value" label="Máximo Vermelho Esquerda" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="min_orange_left_value" label="Mínimo Laranja Esquerda" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="max_orange_left_value" label="Máximo Laranja Esquerda" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="min_yellow_left_value" label="Mínimo Amarelo Esquerda" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="max_yellow_left_value" label="Máximo Amarelo Esquerda" fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <NumberInput source="min_green_value" label="Mínimo Verde" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput source="max_green_value" label="Máximo Verde" fullWidth />
          </Grid>

          <Grid item xs={12} sm={2}>
            <NumberInput source="min_yellow_right_value" label="Mínimo Amarelo Direita" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="max_yellow_right_value" label="Máximo Amarelo Direita" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="min_orange_right_value" label="Mínimo Laranja Direita" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="max_orange_right_value" label="Máximo Laranja Direita" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="min_red_right_value" label="Mínimo Vermelho Direita" fullWidth />
          </Grid>
          <Grid item xs={12} sm={2}>
            <NumberInput source="max_red_right_value" label="Máximo Vermelho Direita" fullWidth />
          </Grid>

          <Grid item xs={12} sm={12}>
            <CustomRichTextInput source="description" label="Descrição" fullWidth />
          </Grid>

          <Grid item xs={12} sm={12}>
            <BooleanInput source="active" label="Ativo" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default TypeExamEdit;
