import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  useRecordContext
} from "react-admin";
import { hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";
import ExamValueInput from "../../inputs/exam-value-input";

const Schema = Yup.object(
{
  check_up_date: hasValue(),
  exams: Yup.array().of(Yup.object().shape(
  {
    exam_type_id: hasValue(),
    measured_value: Yup.string().required("Campo obrigatório")
  }))
});

const optionRendererExamType = (examType) => `${examType.name} - ${examType.gender.name}`;

const ExamsInput = () =>
{
  const record = useRecordContext();

  return (
    <ArrayInput label="Exames" source="exams">
      <SimpleFormIterator disableReordering disableClear fullWidth inline>
        <ReferenceInput source="exam_type_id" filter={{ gender: record.customer.profile.gender.type }} sort={{ field: "name", order: "ASC" }} reference="setup/exam-types">
          <SelectInput label="Exame" optionText={optionRendererExamType} optionValue="id" />
        </ReferenceInput>
        <ExamValueInput source="measured_value" label="Valor Aferido" />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const CustomerCheckUpEdit = () =>
{
  return (
    <Edit
      title={ <EditTitle subtitle="Editar Check-Up: " source="customer.profile.name" /> }
      redirect="list"
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />} >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={1}>
            <TextInput source="id" label="Id" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextInput source="customer.profile.name" label="Paciente" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={2}>
            <DateTimeInput label="Data" source="check_up_date" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <BooleanInput source="published" label="Publicado" sx={{ marginTop: "10px" }} />
          </Grid>

          <Grid item xs={12} sm={12}>
            <ExamsInput />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default CustomerCheckUpEdit;
