import React from "react";
import { Admin, Resource, Layout } from "react-admin";
import Login from "./view/login";
import Dashboard from "./view/dashboard";
import polyglotI18nProvider from "ra-i18n-polyglot";
import portugueseMessages from "./i18n/pt";
import dataProvider from "./provider/data-provider";
import authProvider from "./provider/auth-provider";
import config from "./config/config";

// ADMIN
import AdminIcon from "@mui/icons-material/SupervisedUserCircle";
import AdminList from "./container/admin/list";
import AdminCreate from "./container/admin/create";
import AdminEdit from "./container/admin/edit";
import AdminShow from "./container/admin/show";

// PRIVACY POLICY
import PrivacyPolicyIcon from "@mui/icons-material/PrivacyTip";
import PrivacyPolicyList from "./container/privacy_policy/list";
import PrivacyPolicyCreate from "./container/privacy_policy/create";
import PrivacyPolicyShow from "./container/privacy_policy/show";

// TERMS OF USE
import TermsOfUseIcon from "@mui/icons-material/Gavel";
import TermsOfUseList from "./container/terms_of_use/list";
import TermsOfUseCreate from "./container/terms_of_use/create";
import TermsOfUseShow from "./container/terms_of_use/show";

// LEGISLATION PROTECT DATA
import LegislationProtectDataIcon from "@mui/icons-material/Https";
import LegislationProtectDataList from "./container/legislation_protect_data/list";
import LegislationProtectDataCreate from "./container/legislation_protect_data/create";
import LegislationProtectDataShow from "./container/legislation_protect_data/show";

// EXAM GROUP
import ExamGroupIcon from "@mui/icons-material/Medication";
import ExamGroupList from "./container/exam_group/list";
import ExamGroupEdit from "./container/exam_group/edit";
import ExamGroupShow from "./container/exam_group/show";

// EXAM TYPE
import ExamTypeIcon from "@mui/icons-material/Vaccines";
import ExamTypeList from "./container/exam_type/list";
import ExamTypeEdit from "./container/exam_type/edit";
import ExamTypeShow from "./container/exam_type/show";

// PLAN
import PlanIcon from "@mui/icons-material/AddCard";
import PlanList from "./container/plan/list";
import PlanCreate from "./container/plan/create";
import PlanEdit from "./container/plan/edit";
import PlanShow from "./container/plan/show";

// NOTIFICATION
import NotificationIcon from "@mui/icons-material/Notifications";
import NotificationList from "./container/notification/list";
import NotificationCreate from "./container/notification/create";
import NotificationEdit from "./container/notification/edit";
import NotificationShow from "./container/notification/show";

// CUSTOMER
import CustomerIcon from "@mui/icons-material/PeopleAlt";
import CustomerList from "./container/customer/list";
import CustomerCreate from "./container/customer/create";
import CustomerEdit from "./container/customer/edit";
import CustomerShow from "./container/customer/show";

// CUSTOMER CHECK UP
import CustomerCheckUpIcon from "@mui/icons-material/MonitorHeart";
import CustomerCheckUpList from "./container/customer_check_up/list";
import CustomerCheckUpCreate from "./container/customer_check_up/create";
import CustomerCheckUpEdit from "./container/customer_check_up/edit";
import CustomerCheckUpShow from "./container/customer_check_up/show";

import theme from "./theme";
import Sidebar from "./components/sidebar";

const i18nProvider = polyglotI18nProvider(() => portugueseMessages);

const App = () => (
  <Admin
    theme={theme}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={Login}
    dashboard={Dashboard}
    layout={(props) => <Layout {...props} sidebar={Sidebar} />}
    title="Dashboard"
  >
    {(permissions) =>
    [
      // ADMIN
      <Resource
        name="admin"
        options={{ label: "Usuários Admin" }}
        icon={AdminIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.users_admin)) ? AdminList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.users_admin)) ? AdminCreate : null }
        edit={ (permissions && permissions.some((e) => e.key === config.permissions.users_admin)) ? AdminEdit : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.users_admin)) ? AdminShow : null }
      />,

      // PRIVACY POLICY
      <Resource
        name="privacy-policy"
        options={{ label: "Políticas de Privacidade" }}
        icon={PrivacyPolicyIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.privacy_policy)) ? PrivacyPolicyList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.privacy_policy)) ? PrivacyPolicyCreate : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.privacy_policy)) ? PrivacyPolicyShow : null }
      />,

      // TERMS OF USE
      <Resource
        name="terms-of-use"
        options={{ label: "Termos de Uso" }}
        icon={TermsOfUseIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.terms_of_use)) ? TermsOfUseList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.terms_of_use)) ? TermsOfUseCreate : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.terms_of_use)) ? TermsOfUseShow : null }
      />,

      // LEGISLATION PROTECT DATA
      <Resource
        name="legislation-protect-data"
        options={{ label: "LGPD" }}
        icon={LegislationProtectDataIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.legislation_protect_data)) ? LegislationProtectDataList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.legislation_protect_data)) ? LegislationProtectDataCreate : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.legislation_protect_data)) ? LegislationProtectDataShow : null }
      />,

      // EXAM GROUP
      <Resource
        name="exam-group"
        options={{ label: "Grupos de Exame" }}
        icon={ExamGroupIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.exam_groups)) ? ExamGroupList : null }
        edit={ (permissions && permissions.some((e) => e.key === config.permissions.exam_groups)) ? ExamGroupEdit : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.exam_groups)) ? ExamGroupShow : null }
      />,

      // EXAM TYPE
      <Resource
        name="exam-type"
        options={{ label: "Tipos de Exame" }}
        icon={ExamTypeIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.exam_types)) ? ExamTypeList : null }
        edit={ (permissions && permissions.some((e) => e.key === config.permissions.exam_types)) ? ExamTypeEdit : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.exam_types)) ? ExamTypeShow : null }
      />,

      // PLAN
      <Resource
        name="plan"
        options={{ label: "Planos" }}
        icon={PlanIcon}
        list={ (permissions && permissions.some(e => e.key === config.permissions.plans)) ? PlanList : null }
        create={ (permissions && permissions.some(e => e.key === config.permissions.plans)) ? PlanCreate : null }
        edit={ (permissions && permissions.some(e => e.key === config.permissions.plans)) ? PlanEdit : null }
        show={ (permissions && permissions.some(e => e.key === config.permissions.plans)) ? PlanShow : null }
      />,

      // NOTIFICATION
      <Resource
        name="notification"
        options={{ label: "Notificações" }}
        icon={NotificationIcon}
        list={ (permissions && permissions.some(e => e.key === config.permissions.notifications)) ? NotificationList : null }
        create={ (permissions && permissions.some(e => e.key === config.permissions.notifications)) ? NotificationCreate : null }
        edit={ (permissions && permissions.some(e => e.key === config.permissions.notifications)) ? NotificationEdit : null }
        show={ (permissions && permissions.some(e => e.key === config.permissions.notifications)) ? NotificationShow : null }
      />,

      // CUSTOMER
      <Resource
        name="customer"
        options={{ label: "Pacientes" }}
        icon={CustomerIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.customers)) ? CustomerList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.customers)) ? CustomerCreate : null }
        edit={ (permissions && permissions.some((e) => e.key === config.permissions.customers)) ? CustomerEdit : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.customers)) ? CustomerShow : null }
      />,

      // CUSTOMER CHECK UP
      <Resource
        name="customer-check-up"
        options={{ label: "Check-ups" }}
        icon={CustomerCheckUpIcon}
        list={ (permissions && permissions.some((e) => e.key === config.permissions.check_ups)) ? CustomerCheckUpList : null }
        create={ (permissions && permissions.some((e) => e.key === config.permissions.check_ups)) ? CustomerCheckUpCreate : null }
        edit={ (permissions && permissions.some((e) => e.key === config.permissions.check_ups)) ? CustomerCheckUpEdit : null }
        show={ (permissions && permissions.some((e) => e.key === config.permissions.check_ups)) ? CustomerCheckUpShow : null }
      />
    ]}
  </Admin>
);

export default App;
