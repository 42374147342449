import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";
import GroupButtons from "../../components/group-buttons";
import CustomFilterCustomerCheckUp from "../../filter/custom-filter-customer-check-up";
import CustomListDefaultToolbar from "../../ui/custom-list-default-toolbar";
import CustomerCheckUpExporter from "../../exporter/customer-check-up-exporter";
import ButtonDetails from "../../ui/button-details";
import ButtonEdit from "../../ui/button-edit";
import ButtonDeleteCheckUp from "../../ui/button-delete-check-up";

const CustomerCheckUpList = () => (
  <List
    title="Check-Ups"
    sort={{ field: "id", order: "DESC" }}
    filters={CustomFilterCustomerCheckUp}
    exporter={CustomerCheckUpExporter}
    actions={<CustomListDefaultToolbar />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="customer.profile.name" label="Nome" sortable={false} />
      <TextField source="check_up_date_format" label="Data" sortable={false} />
      <TextField source="count_exams" label="Exames" sortable={false} />
      <BooleanField source="published" label="Publicado" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <GroupButtons>
        <ButtonDetails />
        <ButtonEdit />
        <ButtonDeleteCheckUp />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default CustomerCheckUpList;
