import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";
import CustomFilterExamType from "../../filter/custom-filter-exam-type";
import CustomListDefaultToolbar from "../../ui/custom-list-default-toolbar";
import ExamTypeExporter from "../../exporter/exam-type-exporter";
import ButtonDetails from "../../ui/button-details";
import ButtonEdit from "../../ui/button-edit";
import GroupButtons from "../../components/group-buttons";

const ExamTypeList = (props) => (
  <List
    title="Tipos de Exame"
    sort={{ field: "id", order: "DESC" }}
    filters={CustomFilterExamType}
    exporter={ExamTypeExporter}
    actions={<CustomListDefaultToolbar hasCreate={false} />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="exam_group.short_name" label="Grupo" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="type_reference.symbol" label="Referência" sortable={false} />
      <TextField source="gender.name" label="Gênero" sortable={false} />
      <TextField source="ideal_value" label="Valor Ideal" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <GroupButtons>
        <ButtonDetails />
        <ButtonEdit />
      </GroupButtons>
    </Datagrid>
  </List>
);

export default ExamTypeList;
