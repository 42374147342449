import CustomDeleteWithConfirmButton from "../custom/custom-delete-confirm-button";

function ButtonDeleteCheckUp()
{
  return (
    <CustomDeleteWithConfirmButton
      confirmTitle="Deletar"
      confirmContent="Tem certeza que deseja deletar esse check-up?"
    />
  );
}

export default ButtonDeleteCheckUp;
