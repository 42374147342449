import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput
} from "react-admin";
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  name: hasValue(),
  short_name: hasValue()
});

const ExamGroupEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Grupo de Exames: " source="name" />}
      redirect="list"
      mutationMode="pessimistic" >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="Id" disabled />
        <TextInput source="name" label="Nome" fullWidth />
        <TextInput source="short_name" label="Nome Abreviado" fullWidth />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  );
};

export default ExamGroupEdit;
