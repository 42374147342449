import {
  Show,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Tab,
  TextField,
  FunctionField,
  BooleanField,
  DateField,
  ArrayField,
  Datagrid
} from 'react-admin';

const CustomerCheckUpShow = props => (
  <Show
    title="Check-Up">
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />}>
      <Tab label="Dados">
        <TextField source="id"/>
        <TextField source="customer.profile.name" label="Nome" sortable={false} />
        <TextField source="check_up_date_format" label="Data" sortable={false} />
        <TextField source="count_exams" label="Exames" sortable={false} />
        <BooleanField source="published" label="Publicado" sortable={false} />
        <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
      </Tab>
      <Tab label="Exames">
        <ArrayField source="exams" label="">
          <Datagrid bulkActionButtons={false}>
            <FunctionField render={record => `${record.exam_type.name} -  ${record.exam_type.gender.name}`} label="Exame" />
            <TextField source="measured_value" label="Valor Aferido" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default CustomerCheckUpShow;
