import {
  Create,
  SimpleForm,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomRichTextInput from '../../ui/custom-rich-text-input';
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  title: hasValue(),
  text: hasValue()
});

const NotificationCreate = () =>
{
  return (
    <Create
      title="Nova Notificação"
      redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <ReferenceArrayInput label="Paciente(s)" source="customers" reference="setup/customers">
          <SelectArrayInput label="Paciente(s)" optionText="profile.name" optionValue="id" fullWidth />
        </ReferenceArrayInput>
        <TextInput source="title" label="Título" fullWidth />
        <CustomRichTextInput source="text" label="Texto" fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default NotificationCreate;
