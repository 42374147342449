import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (legislationsProtectData) => {
  const data = legislationsProtectData.map((legislationProtectData) => ({
    id: legislationProtectData.id,
    criado_por: legislationProtectData.admin.name,
    texto: legislationProtectData.text_strip,
    publicado: (legislationProtectData.published ? "Sim" : "Não"),
    criado_em: legislationProtectData.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "criado_por", "texto", "publicado", "criado_em"],
  });
  downloadCSV(csv, "legislationsProtectData");
};

export default exporter;
