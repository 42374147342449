import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (customers) => {
  const data = customers.map((customer) => ({
    id: customer.id,
    cpf: customer.document,
    nome: customer.profile.name,
    email: customer.profile.email,
    sexo: customer.profile.gender.name,
    telefone: customer.profile.phone,
    celular: customer.profile.cellphone,
    nascimento: customer.profile.birthdate,
    peso: customer.profile.weight,
    altura: customer.profile.height,
    plano: customer.plan.name,
    criado_em: customer.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "cpf", "nome", "email", "sexo", "telefone", "celular",
             "nascimento", "peso", "altura", "plano", "criado_em"]
  });
  downloadCSV(csv, "customers");
};

export default exporter;
