import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput
} from "react-admin";
import {
  leastOneOption,
  hasValue,
  document,
  email,
  cellphone,
  phone,
  birthdate
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";
import MaskTextInput from "../../inputs/mask-text-input";

const Schema = Yup.object(
{
  plan_id: leastOneOption(),
  gender: leastOneOption(),
  document: document(),
  name: hasValue(),
  email: email(false),
  cellphone: cellphone(),
  phone: phone(),
  birthdate: birthdate()
});

const CustomerCreate = () =>
{
  return (
    <Create title="Novo Paciente" redirect="list">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />} >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <ReferenceInput source="plan_id" sort={{ field: "name", order: "ASC" }} reference="setup/plans" >
              <SelectInput label="Plano" optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceInput source="gender" sort={{ field: "name", order: "ASC" }} reference="setup/genders" >
              <SelectInput label="Gênero" optionText="name" optionValue="type" fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MaskTextInput source="document" label="CPF" mask="000.000.000-00" definitions={{ "#": /[0-9]/ }} fullWidth isRequired />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="name" label="Nome" fullWidth />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput source="email" label="E-mail" type="email" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MaskTextInput source="cellphone" label="Celular" mask="(00) 00000-0000" definitions={{ "#": /[0-9]/ }} fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MaskTextInput source="phone" label="Telefone" mask="(00) 0000-0000" definitions={{ "#": /[0-9]/ }} fullWidth />
          </Grid>

          <Grid item xs={12} sm={4}>
            <MaskTextInput source="birthdate" label="Nascimento" mask="00/00/0000" definitions={{ "#": /[0-9]/ }} fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberInput source="weight" label="Peso" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberInput source="height" label="Altura" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CustomerCreate;
