var env = process.env.REACT_APP_NODE_ENV || "development";

var config =
{
  development:
  {
    api:
    {
      api_key: "600043ed4bbaadc65ea3b01262959cf3",
      url: "https://t4kerjp5eb.execute-api.us-east-1.amazonaws.com"
    },
    local_storage:
    {
      user: "user_admin_dashboard",
      auth: "auth_admin_dashboard"
    },
    jwt:
    {
      key: "f626601536ff2a6eff6ffbbf99f34c09"
    },
    permissions:
    {
      users_admin: "cc8731b7c2ae4ab9edb0c0bb736e41f6",
      privacy_policy: "b44cdaa5463fe345c39fe95929205d47",
      terms_of_use: "90cb98cdd563ca82ee6b1c4426f40834",
      legislation_protect_data: "450bf31dc4fde6d1f650a097709ed64c",
      exam_groups: "9607fe77d20a3b5c19ef8d31f62c14b8",
      exam_types: "95eb071fa730620cc8bd3f699c4d706f",
      plans: "50025fbba297c401c6feea53f28a5e1d",
      notifications: "2642f051c98bd759e0888bc5d5b22489",
      customers: "a009c4c6d0aa7d37d9a999a79f81c5a1",
      check_ups: "a7518c9878eb870c6573eb31a1836c18"
    }
  },
  production:
  {
    api:
    {
      api_key: "600043ed4bbaadc65ea3b01262959cf3",
      url: "https://qmtf718r97.execute-api.us-east-1.amazonaws.com"
    },
    local_storage:
    {
      user: "user_admin_dashboard",
      auth: "auth_admin_dashboard"
    },
    jwt:
    {
      key: "f626601536ff2a6eff6ffbbf99f34c09"
    },
    permissions:
    {
      users_admin: "cc8731b7c2ae4ab9edb0c0bb736e41f6",
      privacy_policy: "b44cdaa5463fe345c39fe95929205d47",
      terms_of_use: "90cb98cdd563ca82ee6b1c4426f40834",
      legislation_protect_data: "450bf31dc4fde6d1f650a097709ed64c",
      exam_groups: "9607fe77d20a3b5c19ef8d31f62c14b8",
      exam_types: "95eb071fa730620cc8bd3f699c4d706f",
      plans: "50025fbba297c401c6feea53f28a5e1d",
      notifications: "2642f051c98bd759e0888bc5d5b22489",
      customers: "a009c4c6d0aa7d37d9a999a79f81c5a1",
      check_ups: "a7518c9878eb870c6573eb31a1836c18"
    }
  }
};

module.exports = config[env];
