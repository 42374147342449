import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (plans) => {
  const data = plans.map((plan) => ({
    id: plan.id,
    nome: plan.name,
    criado_em: plan.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "nome", "criado_em"],
  });
  downloadCSV(csv, "plans");
};

export default exporter;
