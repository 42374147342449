import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import PlanExporter from '../../exporter/plan-exporter';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';

const PlanList = (props) => (
  <List
    title="Planos"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ PlanExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <ButtonDetails />
      <ButtonEdit />
    </Datagrid>
  </List>
);

export default PlanList;
