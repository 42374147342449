import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField
} from 'react-admin';

const ExamGroupShow = () => (
  <Show
    title="Grupo de Exame">
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="name" label="Nome" />
      <TextField source="short_name" label="Nome Abreviado" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default ExamGroupShow;
