const nextInput = (ref) =>
{
  if (ref.key === 13 ||
      ref.key.toLowerCase() === "enter" ||
      ref.key === 9 ||
      ref.key.toLowerCase() === "tab")
  {
    ref.preventDefault();
    const split = ref.target.name.split(".");
    
    if (split.length > 1)
    {
      const index = parseInt(split[1]);
      const identifier = `${split[0]}.${index + 1}.${split[2]}`;
      const el = document.getElementsByName(identifier);

      if (el && el.length > 0)
      {
        const element = el[0];
        element.focus();
        element.select();
      }
    }
  }
};

export default nextInput;
