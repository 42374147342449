import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, Typography, Stack } from "@mui/material";
import {
  Form,
  TextInput,
  PasswordInput,
  required,
  useTranslate,
  useLogin,
  useNotify,
} from "react-admin";
import IconLogin from "../components/icon-login";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth).catch((error) => {
      setLoading(false);
      notify("E-mail ou senha inválido");
    });
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Stack minHeight="100vh" alignItems="center" justifyContent="center">
        <Stack
          bgcolor="white"
          width={{ xs: "100%", sm: "400px" }}
          padding={2}
          gap={5}
          borderRadius="1em">
          <Stack alignItems="center" gap={1}>
            <IconLogin />
            <Typography sx={{ textAlign: "center", color: (theme) => theme.palette.grey[500] }}>
              Faça o login
            </Typography>
          </Stack>

          <Stack gap={1}>
            <TextInput autoFocus fullWidth source="email" label={translate("ra.auth.email")} disabled={loading} validate={required("Digite o e-mail")}/>
            <PasswordInput fullWidth source="password" label={translate("ra.auth.password")} disabled={loading} validate={required("Digite a senha")} />

            <Button fullWidth type="submit" color="primary" disabled={loading}>
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate("ra.auth.sign_in")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
  typeUser?: string;
  email?: string;
  password?: string;
}
