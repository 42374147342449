import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (examTypes) => {
  const data = examTypes.map((examType) => ({
    id: examType.id,
    grupo: examType.exam_group.short_name,
    referencia: examType.type_reference.symbol,
    genero: examType.gender.name,
    nome: examType.name,
    valor_inicial: examType.initial_reference_value,
    valor_final: examType.final_reference_value,
    valor_ideal: examType.ideal_value,
    valor_minimo: examType.min_value,
    valor_maximo: examType.max_value,
    minimo_vermelho_esquerda: examType.min_red_left_value,
    maximo_vermelho_esquerda: examType.max_red_left_value,
    minimo_laranja_esquerda: examType.min_orange_left_value,
    maximo_laranja_esquerda: examType.max_orange_left_value,
    minimo_amarelo_esquerda: examType.min_yellow_left_value,
    maximo_amarelo_esquerda: examType.max_yellow_left_value,
    minimo_verde: examType.min_green_value,
    maximo_verde: examType.max_green_value,
    minimo_amarelo_direita: examType.min_yellow_right_value,
    maximo_amarelo_direita: examType.max_yellow_right_value,
    minimo_laranja_direita: examType.min_orange_right_value,
    maximo_laranja_direita: examType.max_orange_right_value,
    minimo_vermelho_direita: examType.min_red_right_value,
    maximo_vermelho_direita: examType.max_red_right_value,
    descricao: examType.description_strip,
    criado_em: examType.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "grupo", "referencia", "genero", "nome", "valor_inicial",
             "valor_final", "valor_ideal", "valor_minimo", "valor_maximo",
             "minimo_vermelho_esquerda", "maximo_vermelho_esquerda",
             "minimo_laranja_esquerda", "maximo_laranja_esquerda",
             "minimo_amarelo_esquerda", "maximo_amarelo_esquerda", 
             "minimo_verde", "maximo_verde", "minimo_amarelo_direita",
             "maximo_amarelo_direita", "minimo_laranja_direita",
             "maximo_laranja_direita", "minimo_vermelho_direita",
             "maximo_vermelho_direita", "descricao", "criado_em"]
  });
  downloadCSV(csv, "examTypes");
};

export default exporter;
