import { IMaskInput } from "react-imask";
import { useInput } from "react-admin";
import TextField from "@mui/material/TextField";
import { useRef } from "react";

const MaskTextInput = (props) =>
{
  var input = useRef(null);
  const { onChange, onBlur, ...rest } = props;

  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired
  } = useInput({ onChange, onBlur, ...props });

  const showError = (isTouched || isSubmitted) && invalid;

  return (
    <TextField
      {...field}
      ref={input}
      inputRef={(e) => (input = e)}
      required={isRequired}
      helperText={showError ? error?.message : ""}
      error={showError}
      inputProps={{ mask: props.mask, definitions: props.definitions }}
      InputProps={{ inputComponent: IMaskInput }}
      {...rest}
    />
  );
};

export default MaskTextInput;
