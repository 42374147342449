import React from "react";
import { useRecordContext } from "react-admin";

const EditTitle = ({ subtitle, source, subvalue }) =>
{
  const record = useRecordContext();
  if (record)
  {
    const recordSource = record[source];
    if (recordSource)
    {
        if (subvalue)
          return (
            <span>
              {subtitle} {recordSource[subvalue]}
            </span>
          );
        else
          return (
            <span>
              {subtitle} {recordSource}
            </span>
          );
    }

    return <span>{subtitle}</span>;
  }

  return null;
};

export default EditTitle;
