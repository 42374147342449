import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField
} from 'react-admin';

const AdminShow = () => (
  <Show
    title="Usuário Admin">
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="name" label="Nome" />
      <TextField source="email" label="E-mail" />
      <ArrayField label="Permissões" source="permissions" sortable={false} fieldKey="uuid">
        <SingleFieldList
          linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default AdminShow;
