import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import CustomFilterDefault from '../../filter/custom-filter-default';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import NotificationExporter from '../../exporter/notification-exporter';
import ButtonDetails from '../../ui/button-details';
import ButtonEdit from '../../ui/button-edit';

const NotificationList = (props) => (
  <List
    title="Notificações"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterDefault }
    exporter={ NotificationExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="title" label="Título" sortable={false} />
      <TextField source="count_send" label="Envios" sortable={false} />
      <TextField source="count_read" label="Visualizações" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <ButtonDetails />
      <ButtonEdit />
    </Datagrid>
  </List>
);

export default NotificationList;
