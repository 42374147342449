import TextField from "@mui/material/TextField";
import { useInput } from "react-admin";

import nextInput from "../utils/next-input";
import pasteInput from "../utils/paste-input";

const regex = /[^\d,]+/g;

const ExamValueInput = (props) =>
{
  const { onChange, onBlur, label, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, invalid, error },
    formState: { isSubmitted },
    isRequired
  } = useInput({
    onChange,
    onBlur,
    ...rest
  });
  const showError = (isTouched || isSubmitted) && invalid;

  // REPLACE POINT TO COMMA
  field.value = field.value.toString().replace(/[.]+/g, ',')

  return (
    <TextField
      {...field}
      id={field.name}
      label={label}
      error={showError}
      helperText={(isTouched || isSubmitted) && invalid ? error?.message : ""}
      required={isRequired}
      onKeyDown={nextInput}
      onChange={(e) =>
      {
        field.onChange(e.target.value.replace(regex, ""));
        e.preventDefault();
      }}
      onPaste={(e) =>
      {
        field.onChange(pasteInput(e));
        e.preventDefault();
      }}
      {...rest}
    />
  );
};

export default ExamValueInput;
