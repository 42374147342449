import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const CustomFilterExamType = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Grupo" source="exam_group_id" reference="setup/exam-groups" alwaysOn >
    <SelectInput label="Grupo" optionText="short_name" optionValue="id" />
  </ReferenceInput>,
  <ReferenceInput label="Gênero" source="gender" reference="setup/genders" alwaysOn >
    <SelectInput label="Gênero" optionText="name" optionValue="type" />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterExamType;
