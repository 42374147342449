import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (examsGroup) => {
  const data = examsGroup.map((examsGroup) => ({
    id: examsGroup.id,
    nome: examsGroup.name,
    nome_abreviado: examsGroup.short_name,
    criado_em: examsGroup.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "nome", "nome_abreviado", "criado_em"]
  });
  downloadCSV(csv, "examsGroups");
};

export default exporter;
