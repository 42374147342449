import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const CustomFilterCustomer = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Plano" source="plan_id" reference="setup/plans" alwaysOn >
    <SelectInput label="Plano" optionText="name" optionValue="id" />
  </ReferenceInput>,
  <QuickFilter source="activated" label="Ativos" defaultValue={true} />,
  <QuickFilter source="disabled" label="Desativados" defaultValue={true} />
];

export default CustomFilterCustomer;
