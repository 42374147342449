import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput
} from 'react-admin';
import {
  hasValue
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditTitle from '../../ui/edit-title';
import CustomRichTextInput from '../../ui/custom-rich-text-input';
import CustomSaveToolbar from "../../ui/custom-save-toolbar";

const Schema = Yup.object(
{
  title: hasValue(),
  text: hasValue()
});

const NotificationEdit = () =>
{
  return (
    <Edit
      title={ <EditTitle subtitle="Editar Notificação: " source="title" /> }
      redirect="list"
      mutationMode="pessimistic">
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={ <CustomSaveToolbar /> }>
        <TextInput source="id" label="Id" disabled />
        <TextInput source="title" label="Título" fullWidth />
        <CustomRichTextInput source="text" label="Texto" fullWidth />
        <BooleanInput source="active" label="Ativo" />
      </SimpleForm>
    </Edit>
  )
}

export default NotificationEdit;
