import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (customerCheckUps) => {
  const data = customerCheckUps.map((customerCheckUp) => ({
    id: customerCheckUp.id,
    paciente: customerCheckUp.customer.profile.name,
    data: customerCheckUp.check_up_date_format,
    numero_exames: customerCheckUp.count_exams,
    publicado: (customerCheckUp.published ? "Sim" : "Não"),
    criado_em: customerCheckUp.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "paciente", "data", "numero_exames", "publicado", "criado_em"]
  });
  downloadCSV(csv, "customerCheckUps");
};

export default exporter;
