import React, { useState } from "react";
import {
  Button,
  Form,
  ReferenceArrayInput,
  SelectArrayInput,
  useNotify,
  useRecordContext,
  required,
} from "react-admin";
import slug from "slug";
import fileDownload from "js-file-download";
import paramsToFormData from "../utils/params-to-form-data";
import jwt from "jwt-simple";
import axios from "axios";
import config from "../config/config";
import IconPDF from "@mui/icons-material/PictureAsPdf";
import IconCancel from "@mui/icons-material/Cancel";
import IconSave from "@mui/icons-material/Save";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const apiUrl = config.api.url;
const apiKey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageAuth = config.local_storage.auth;

const headers = () =>
{
  return {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "x-api-key": apiKey,
    "x-token":
      "Bearer " + JSON.parse(jwt.decode(localStorage.getItem(jwt.encode(localStorageAuth, jwtKey)), jwtKey)).auth_token
  };
};

const validateCheckUpDate = [required("Selecione pelo menos 1 check-up!")];
const optionRendererCheckUp = (checkUp) => `Data: ${checkUp.check_up_date_format} - ${checkUp.count_exams} exame(s)`;

function CustomDialogReport()
{
  const record = useRecordContext();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClick = (e) =>
  {
    setShowDialog(true);
    e.stopPropagation();
  };
  const handleCloseClick = (e) =>
  {
    setShowDialog(false);
    e.stopPropagation();
  };
  const handleSubmit = async (values) =>
  {
    if (values.customer_check_up_ids.length > 3)
      notify("Selecione no máximo 3 Check-Ups!", { type: 'error' });
    else
    {
      const options =
      {
        headers: headers(),
        url: `${apiUrl}/customer/${record.id}/report/check-up`,
        data: paramsToFormData(values),
        method: "POST",
        responseType: 'blob'
      };

      setLoading(true);
      axios(options)
      .then(async (res) =>
      {
        fileDownload(res.data, (slug(record.profile.name, { lower: true, replacement: '_' }) + '.pdf'));
        
        setShowDialog(false);
        setLoading(false);
      })
      .catch((error) =>
      {
        notify(error.response.data.error, { type: 'error' });
        setLoading(false);
      });
    }
  };

  return (
    <>
      <Button onClick={handleClick} label="Relatório">
        <IconPDF />
      </Button>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Novo Relatório de Check-Up">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>Novo Relatório de Check-Up</DialogTitle>
          <DialogContent>
            <ReferenceArrayInput source="customer_check_up_ids" filter={{ customer_id: record.id }} sort={{ field: "check_up_date", order: "ASC" }} reference="customer/check-up/dates">
              <SelectArrayInput label="Selecione o(s) check-up(s) - Máximo 3" optionText={optionRendererCheckUp} optionValue="id" fullWidth validate={validateCheckUpDate}/>
            </ReferenceArrayInput>
          </DialogContent>
          <DialogActions>
            <Button
              label="Cancelar"
              onClick={handleCloseClick}
              disabled={loading}>
              <IconCancel />
            </Button>
            <Button label="Gerar" type="submit" disabled={loading}>
              <IconSave />
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}

export default CustomDialogReport;
