import { useWatch } from "react-hook-form";
import { ArrayInput, SimpleFormIterator, TextInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import ExamValueInput from "../inputs/exam-value-input";

const ExamsInput = ({ customers, exams, ...props }) =>
{
  var examsFiltered = [];
  const form = useFormContext();
  const customer_id = useWatch({ name: "customer_id" });
  const customer = customers.find((cus) => cus.id === customer_id);

  useEffect(() =>
  {
    const values = form.getValues();
    form.reset(
    {
      ...values,
      exams: examsFiltered?.map((item) =>
      {
        return { ...item, measured_value: null };
      })
    });
  }, [customer_id]);

  if (customer_id == null)
    return;
  else
  {
    // FILTER EXAMS BY GENDER
    examsFiltered = exams.filter((examType) => {
      return examType.gender.type === customer.profile.gender.type;
    });
    return (
      <ArrayInput
        key={customer_id}
        label="Exames"
        source="exams"
        defaultValue={examsFiltered}>
        <SimpleFormIterator getItemLabel={(index) => `#${index + 1}`} disableReordering disableClear disableAdd inline>
          <TextInput label="Exame" source="name" disabled />
          <TextInput label="Valor Mínimo" source="min_value" disabled />
          <TextInput label="Valor Máximo" source="max_value" disabled />
          <TextInput label="Valor Ideal" source="ideal_value" disabled />
          <ExamValueInput label="Valor Aferido" source="measured_value" />
        </SimpleFormIterator>
      </ArrayInput>
    );
  }
};

export default ExamsInput;
