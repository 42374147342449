import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField
} from 'react-admin';

const CustomerShow = () => (
  <Show
    title="Paciente">
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="document" label="CPF" sortable={false} />
      <TextField source="profile.name" label="Nome" sortable={false} />
      <TextField source="profile.email" label="E-mail" sortable={false} />
      <TextField source="profile.gender.name" label="Sexo" sortable={false} />
      <TextField source="profile.phone" label="Telefone" sortable={false} />
      <TextField source="profile.cellphone" label="Celular" sortable={false} />
      <TextField source="profile.birthdate" label="Nascimento" sortable={false} />
      <TextField source="profile.weight" label="Peso" sortable={false} />
      <TextField source="profile.height" label="Altura" sortable={false} />
      <TextField source="plan.name" label="Plano" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default CustomerShow;
