import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (notifications) => {
  const data = notifications.map((notification) => ({
    id: notification.id,
    titulo: notification.title,
    texto: notification.text_strip,
    visualizacoes: notification.count_read,
    criado_em: notification.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "titulo", "texto", "visualizacoes", "criado_em"],
  });
  downloadCSV(csv, "notifications");
};

export default exporter;
