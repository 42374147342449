import { defaultTheme } from "react-admin";

export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#6bcac1",
    },
    secondary: {
      main: "#f9776a",
    },
    error: {
      main: "#ff0000",
    },
    red: {
      800: "#DE1700",
      900: "#FF0000",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
  components: {
    ...defaultTheme.components,
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableelevation: "true",
        fullwidth: "true",
      },
    },
    MuiButton: {
      defaultProps: {
        size: "large",
        variant: "contained",
      },
      styleOverrides: {
        root: {
          color: "white",
          fontWeight: "bold",
          shadow: "none",
          boxShadow: "none",
          "&:hover": {
            shadow: "none",
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullwidth: "true",
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .RaConfirm-confirmPrimary": {
            color: "white !important",
          },
          "& button": {
            flex: 1,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 24px 20px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          width: "auto !important",
        },
      },
    },
  },
};

export default themeOptions;
