import { Box, Typography } from "@mui/material";

const WelcomeContent = () => {
  return (
    <Box padding={2}>
      <Typography>
        Qualquer dúvida sobre como administrar ou algo que queira desenvolver,
        entre em contato com a gente pelo e-mail.
        <br />
        <a href="mailto:contato@physis.io?subject=Clinlife dashboard: Dúvidas">
          contato@physis.io
        </a>
      </Typography>
    </Box>
  );
};

export default WelcomeContent;
