import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";
import CustomFilterDefault from "../../filter/custom-filter-default";
import CustomListDefaultToolbar from "../../ui/custom-list-default-toolbar";
import ExamGroupExporter from "../../exporter/exam-group-exporter";
import ButtonDetails from "../../ui/button-details";
import ButtonEdit from "../../ui/button-edit";

const ExamGroupList = (props) => (
  <List
    title="Grupos de Exame"
    sort={{ field: "id", order: "DESC" }}
    filters={CustomFilterDefault}
    exporter={ExamGroupExporter}
    actions={<CustomListDefaultToolbar hasCreate={false} />} >
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" label="Nome" sortable={false} />
      <TextField source="short_name" label="Nome Abreviado" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <ButtonDetails />
      <ButtonEdit />
    </Datagrid>
  </List>
);

export default ExamGroupList;
