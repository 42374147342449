import { SearchInput, ReferenceInput, SelectInput } from "react-admin";
import QuickFilter from "../ui/quick-filter";

const CustomFilterCustomerCheckUp = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput label="Paciente" source="customer_id" reference="setup/customers" alwaysOn>
    <SelectInput label="Paciente" optionText="profile.name" optionValue="id" />
  </ReferenceInput>,
  <QuickFilter source="published" label="Publicados" defaultValue={true} />,
  <QuickFilter source="unpublished" label="Não Publicados" defaultValue={true} />
];

export default CustomFilterCustomerCheckUp;
