import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField
} from 'react-admin';
import CustomFilterPrivacyPolicy from '../../filter/custom-filter-privacy-policy';
import CustomListDefaultToolbar from '../../ui/custom-list-default-toolbar';
import PrivacyPolicyExporter from '../../exporter/privacy-policy-exporter';
import ButtonDetails from '../../ui/button-details';

const PrivacyPolicyList = (props) => (
  <List
    title="Políticas de Privacidade"
    sort={{ field: 'id', order: 'DESC' }}
    filters={ CustomFilterPrivacyPolicy }
    exporter={ PrivacyPolicyExporter }
    actions={ <CustomListDefaultToolbar /> }>
    <Datagrid
      bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="admin.name" label="Criado por" sortable={false} />
      <BooleanField source="published" label="Publicado" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} showTime />
      <ButtonDetails />
    </Datagrid>
  </List>
);

export default PrivacyPolicyList;
