import {
  Create,
  SimpleForm,
  AutocompleteInput,
  DateTimeInput,
  BooleanInput,
  FormDataConsumer,
  useGetList
} from "react-admin";
import { leastOneOption, hasValue } from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";
import ExamsInput from "../../inputs/exams-input";

const Schema = Yup.object(
{
  customer_id: leastOneOption(),
  check_up_date: hasValue(),
  exams: Yup.array().of(Yup.object().shape(
  {
    measured_value: Yup.string().required("Campo obrigatório")
  }))
});

const CustomerCheckUpCreate = () =>
{
  const { data: dataCustomers, isLoading: isLoadingCustomers } = useGetList("setup/customers");
  const { data: dataExamsType, isLoading: isLoadingExamsType } = useGetList("setup/exam-types");

  if (isLoadingCustomers || isLoadingExamsType)
    return;
  else
    return (
      <Create title="Novo Check-Up" redirect="list">
        <SimpleForm
          noValidate
          resolver={yupResolver(Schema)}
          toolbar={<CustomSaveToolbar />}>
          <Grid container columnSpacing={2}>
            <Grid item xs={5}>
              <AutocompleteInput label="Paciente" source="customer_id" optionText="profile.name" optionValue="id" choices={dataCustomers} limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 1 : false; }} fullWidth />
            </Grid>
            <Grid item xs={2}>
              <DateTimeInput label="Data" source="check_up_date" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <BooleanInput source="published" label="Publicar" sx={{ marginTop: "10px" }} />
            </Grid>
          </Grid>
          <FormDataConsumer>
          {
            ({ formData }) =>
            formData && formData.customer_id ? (
              <ExamsInput customers={dataCustomers} exams={dataExamsType} />
            ) : null
          }
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    );
};

export default CustomerCheckUpCreate;
