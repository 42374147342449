import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  RichTextField,
  ArrayField,
  SingleFieldList,
  ChipField
} from 'react-admin';

const NotificationShow = () => (
  <Show
    title="Notificação">
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="title" label="Título" sortable={false} />
      <TextField source="count_send" label="Envios" sortable={false} />
      <TextField source="count_read" label="Visualizações" sortable={false} />
      <RichTextField source="text" label="Texto" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
      <ArrayField label="Paciente(s)" source="customers" sortable={false} fieldKey="uuid">
        <SingleFieldList
          linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default NotificationShow;
