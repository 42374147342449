import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

const exporter = (privacyPolicies) => {
  const data = privacyPolicies.map((privacyPolicy) => ({
    id: privacyPolicy.id,
    criado_por: privacyPolicy.admin.name,
    texto: privacyPolicy.text_strip,
    publicado: (privacyPolicy.published ? "Sim" : "Não"),
    criado_em: privacyPolicy.created_at_format
  }));
  const csv = convertToCSV({
    data,
    fields: ["id", "criado_por", "texto", "publicado", "criado_em"],
  });
  downloadCSV(csv, "privacyPolicies");
};

export default exporter;
