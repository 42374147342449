import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  BooleanField,
  DateField
} from 'react-admin';

const ExamTypeShow = () => (
  <Show
    title="Tipo de Exame">
    <SimpleShowLayout>
      <TextField source="id"/>
      <TextField source="exam_group.short_name" label="Grupo" sortable={false} />
      <TextField source="type_reference.symbol" label="Referência" sortable={false} />
      <TextField source="gender.name" label="Gênero" sortable={false} />
      <TextField source="name" label="Nome" />
      <TextField source="initial_reference_value" label="Valor Inicial" sortable={false} />
      <TextField source="final_reference_value" label="Valor Final" sortable={false} />
      <TextField source="ideal_value" label="Valor Ideal" sortable={false} />
      <TextField source="min_value" label="Valor Mínimo" sortable={false} />
      <TextField source="max_value" label="Valor Máximo" sortable={false} />
      <TextField source="min_red_left_value" label="Mínimo Vermelho Esquerda" sortable={false} />
      <TextField source="max_red_left_value" label="Máximo Vermelho Esquerda" sortable={false} />
      <TextField source="min_orange_left_value" label="Mínimo Laranja Esquerda" sortable={false} />
      <TextField source="max_orange_left_value" label="Máximo Laranja Esquerda" sortable={false} />
      <TextField source="min_yellow_left_value" label="Mínimo Amarelo Esquerda" sortable={false} />
      <TextField source="max_yellow_left_value" label="Máximo Amarelo Esquerda" sortable={false} />
      <TextField source="min_green_value" label="Mínimo Verde" sortable={false} />
      <TextField source="max_green_value" label="Máximo Verde" sortable={false} />
      <TextField source="min_yellow_right_value" label="Mínimo Amarelo Direita" sortable={false} />
      <TextField source="max_yellow_right_value" label="Máximo Amarelo Direita" sortable={false} />
      <TextField source="min_orange_right_value" label="Mínimo Laranja Direita" sortable={false} />
      <TextField source="max_orange_right_value" label="Máximo Laranja Direita" sortable={false} />
      <TextField source="min_red_right_value" label="Mínimo Vermelho Direita" sortable={false} />
      <TextField source="max_red_right_value" label="Máximo Vermelho Direita" sortable={false} />
      <RichTextField source="description" label="Descrição" sortable={false} />
      <BooleanField source="active" label="Ativo" sortable={false} />
      <DateField source="created_at" locales="pt-BR" label="Criado em" showTime />
    </SimpleShowLayout>
  </Show>
);

export default ExamTypeShow;
