import { Stack } from "@mui/system";
function GroupButtons({ children })
{
  return (
    <Stack direction="row" gap={1}>
      {children}
    </Stack>
  );
}

export default GroupButtons;
