import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  PasswordInput,
  BooleanInput
} from "react-admin";
import {
  leastOneOption,
  hasValue,
  document,
  email,
  cellphone,
  phone,
  birthdate
} from "../../utils/schemas";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import EditTitle from "../../ui/edit-title";
import CustomSaveToolbar from "../../ui/custom-save-toolbar";
import MaskTextInput from "../../inputs/mask-text-input";

const Schema = Yup.object(
{
  plan: Yup.object().shape({
    id: leastOneOption()
  }),
  document: document(),
  profile: Yup.object().shape({
    name: hasValue(),
    email: email(false),
    cellphone: cellphone(),
    phone: phone(),
    birthdate: birthdate(),
    gender: Yup.object().shape({
      type: leastOneOption()
    })
  })
});

const CustomerEdit = () =>
{
  return (
    <Edit
      title={<EditTitle subtitle="Editar Paciente: " source="profile" subvalue="name" />}
      redirect="list"
      mutationMode="pessimistic" >
      <SimpleForm
        noValidate
        resolver={yupResolver(Schema)}
        toolbar={<CustomSaveToolbar />} >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={2}>
            <TextInput source="id" label="Id" fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={5}>
            <ReferenceInput source="plan.id" sort={{ field: "name", order: "ASC" }} reference="setup/plans" >
              <SelectInput label="Plano" optionText="name" optionValue="id" fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={5}>
            <ReferenceInput source="profile.gender.type" sort={{ field: "name", order: "ASC" }} reference="setup/genders" >
              <SelectInput label="Gênero" optionText="name" optionValue="type" fullWidth />
            </ReferenceInput>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MaskTextInput source="document" label="CPF" fullWidth mask="000.000.000-00" definitions={{ "#": /[0-9]/ }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source="profile.name" label="Nome" fullWidth />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput source="profile.email" label="E-mail" type="email" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MaskTextInput source="profile.phone" label="Telefone" mask="(00) 0000-0000" definitions={{ "#": /[0-9]/ }} fullWidth />
          </Grid>

          <Grid item xs={12} sm={4}>
            <MaskTextInput source="profile.cellphone" label="Celular" mask="(00) 00000-0000" definitions={{ "#": /[0-9]/ }} fullWidth />
          </Grid>

          <Grid item xs={12} sm={4}>
            <MaskTextInput source="profile.birthdate" label="Nascimento" mask="00/00/0000" definitions={{ "#": /[0-9]/ }} fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberInput source="profile.weight" label="Peso" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberInput source="profile.height" label="Altura" fullWidth />
          </Grid>

          <Grid item xs={12} sm={4}>
            <PasswordInput source="password" label="Senha" fullWidth  />
          </Grid>
          <Grid item xs={12} sm={8}>
            <BooleanInput source="active" label="Ativo" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default CustomerEdit;
